<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="table"
        title="평가대상 목록 - 작업/유해위험요인"
        :columns="grid.columns"
        gridHeight="auto"
        :data="process.fmScenarios"
        :merge="grid.merge"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="false"
        :filtering="false"
        :editable="editable&&!disabled"
        selection="multiple"
        rowKey="ram4mAssessScenarioId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- @rowClick="rowClick" -->
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-chip 
            outline square clickable 
            size="md"
            color="light-green-8" 
            text-color="white"
            icon="contact_support"
            :label="$language('범례')"
            style="height:26px;margin-right:2px !important;"
            v-show="editable&&!disabled&&isSelect&&process.fmScenarios&&process.fmScenarios.length>0"
            class="q-ml-sm">
            <q-popup-proxy>
              <!-- <q-banner>
                <q-chip color="teal" />
                작업 추가
              </q-banner>
              <q-separator inset /> -->
              <q-banner>
                <q-btn color="green" icon="add" label="" size="7px"></q-btn>
                : {{$language('유해위험요인 추가')}}
              </q-banner>
            </q-popup-proxy>
          </q-chip>
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect" label="직접추가" icon="add" @btnClicked="addJob" />
            <c-btn v-show="editable&&!disabled&&isSelect&&checkProcessCd" label="SOP 추가" icon="add" @btnClicked="openSop" />
            <c-btn v-show="editable&&!disabled&&isSelect&&process.fmScenarios&&process.fmScenarios.length>0" label="삭제" icon="remove" @btnClicked="removeSop" />
            <c-btn 
              v-show="editable&&!disabled&&isSelect&&process.fmScenarios&&process.fmScenarios.length>0" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="process.fmScenarios"
              mappingType="PUT"
              label="저장" 
              icon="save"
              @beforeAction="saveSop"
              @btnCallback="saveSopCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardName'">
            <c-text
              :afterIcon="editable&&!disabled&&isSelect ? [
                { name: 'search', click: true, callbackName: 'searchRiskHazard', color: 'teal' },
                { name: 'close', click: true, callbackName: 'removeRiskHazard', color: 'red' }
              ] : null"
              :editable="editable"
              :disabled="disabled||!isSelect"
              :close="false"
              label=""
              name="riskHazardName"
              v-model="props.row['riskHazardName']"
              @searchRiskHazard="searchRowRiskHazard(props, col)"
              @removeRiskHazard="removeRowRiskHazard(props)"
              @dataChange="chagneRiskHazard(props)">
            </c-text>
          </template>
          <template v-else-if="col.name==='jobName'">
            <c-text-column
              :editable="editable&&!disabled&&isSelect"
              :disabled="disabled||!isSelect"
              :col="col"
              :props="props"
              @datachange="datachange(props, col)"
            />
            <div v-if="editable&&!disabled&&isSelect">
              <q-btn-group outline class="ColumInnerBtnGroup">
                <q-btn
                  icon="add"
                  color="green"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props)">
                  <q-tooltip>
                    {{$language('유해위험요인 추가')}}
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </div>
          </template>
          <template v-else-if="col.name==='picture'">
            <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-success" 
              name="image"
              @click.stop="openPicture(props)" /> 
            <!-- <c-upload-picture
              class="upload4m"
              height="35px"
              :attachInfo="{
                isSubmit: '',
                taskClassCd: 'RISK_HAZARD',
                taskKey: props.row.ramRiskHazardId,
              }"
              :editable="editable&&!disabled&&isSelect">
            </c-upload-picture>  -->
          </template>
        </template>
        <!-- <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <q-chip
              color="amber"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click.stop="() => { rowClick(props.row, props.pageIndex) }">
              수정
            </q-chip>
          </template>
        </template> -->
      </c-table>
    </div>
    <q-dialog v-model="sopDialog" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <c-card title="평가대상 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled&&isRowClick&&isSelect" 
                  :disabled="disabled||!isSelect" 
                  label="적용" 
                  icon="save" 
                  @btnClicked="accepts"/>
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="공정"
                  name="processName"
                  v-model="sop.processName">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled || !isRowClick || Boolean(sop.mdmSopId) || !isSelect"
                  :required="true"
                  label="작업"
                  name="jobName"
                  v-model="sop.jobName"
                  @dataChange="jobChange">
                </c-text>
              </div>
              <div class="col-12"> 
                <c-select
                  :required="true"
                  :editable="editable"
                  :disabled="disabled || !isRowClick || !isSelect"
                  codeGroupCd="RAM_4M_RISK_HAZARD_CLASS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="ram4mRiskHazardClassCd"
                  label="평가구분(4M)"
                  v-model="sop.ram4mRiskHazardClassCd"
                ></c-select>
              </div>
              <div class="col-12">
                <c-text
                  :afterIcon="editable&&!disabled&&isRowClick&&isSelect ? [
                    { name: 'search', click: true, callbackName: 'searchRiskHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeRiskHazard', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :disabled="disabled || !isRowClick || !isSelect"
                  :required="true"
                  :close="false"
                  label="유해위험요인 (재해형태)"
                  name="riskHazardName"
                  v-model="sop.riskHazardName"
                  @searchRiskHazard="searchRiskHazard"
                  @removeRiskHazard="removeRiskHazard"
                  @dataChange="chagneRiskHazard">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'fm-target-process',

  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        processes: [], // 대상공정 목록
      }),
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        fmScenarios: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'groupMdmSopId' },
        ],
        columns: [],
        data: [],
        height: '550px'
      },
      sop: {
        ram4mAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        groupMdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: '',  // 안전작업 명
        ramRiskHazardId: '',  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        ram4mRiskHazardClassCd: null,  // 4M 분류 코드
        ram4mRiskHazardClassName: '',  // 4M 분류
        damageTargetName: '',  // 피해대상(key-in)
        damageTypeName: '',  // 재해형태(key-in)
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      rowRiskHazardInfo: {
        row: null,
        col: null,
      },
      isSameJobName: false,
      isRowClick: false,
      isRowAdd: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      rowIndex: 0,
      sopDialog: false,
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.fm.scenario.list.url
      this.saveUrl = transactionConfig.ram.fm.scenario.save.url
      this.deleteUrl = transactionConfig.ram.fm.scenario.delete.url
      // code setting
      this.$comm.getComboItems('RAM_4M_RISK_HAZARD_CLASS_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            required: true,
            type: 'custom',
            // innerBtn: true,
            // btns: [
            //   { label: '', icon: 'add', color: 'green' }
            // ]
          },
          {
            name: 'ram4mRiskHazardClassCd',
            field: 'ram4mRiskHazardClassCd',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            required: true,
            type: 'select',
            comboItems: _result
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인 (재해형태)',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            required: true,
            type: 'custom'
          },
          {
            name: 'picture',
            field: 'picture',
            label: '사진',
            align: 'center',
            type: 'custom',
            style: 'width:80px',
            sortable: false,
          },
        ]
      });
      // list setting
    },
    getSops() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.$set(this.process, 'fmScenarios', _result.data)
      },);
    },
    chagneRiskHazard(props) {
      /**
       * 직적 입력하는 순간 유해위험요인의 id는 null처리
       */
      if (props && props.row) {
        this.$set(props.row, 'ramRiskHazardId', null)
      } else {
        this.sop.ramRiskHazardId = null
      }
    },
    openSop() {
      this.popupOptions.title = 'SOP 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        searchType: '7', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        subProcessCd: this.process.processCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.fmScenarios, { mdmSopId: item.mdmSopId, ramRiskHazardId: item.mdmHazardFactorsId, }) === -1) {
            this.process.fmScenarios.splice(0, 0, {
              ram4mAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
              processCd: this.process.processCd,  // 단위공정 코드
              processName: this.process.processName,
              ramTechniqueCd: 'RT00000025',
              groupMdmSopId: this.process.processCd + '/' + item.mdmSopId,
              mdmSopId: item.mdmSopId,  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
              jobName: item.sopName,  // 안전작업 명
              ramRiskHazardId: item.mdmHazardFactorsId,  // 유해위험요인 번호
              riskHazardName: item.riskHazardName,  // 유해위험요인명
              ram4mRiskHazardClassCd: null,  // 4M 분류 코드
              ram4mRiskHazardClassName: '',  // 4M 분류
              damageTargetName: '',  // 피해대상(key-in)
              damageTypeName: '',  // 재해형태(key-in)
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
        // this.saveSop();
      }
    },
    // rowClick(row, index) {
    //   /**
    //    * 이후에 해당 정보를 클릭 한 후 수정하려고 할 수 있도록 열것임
    //    * 다만 해당 마다 존재하는 키값이 temp로 따진 키가 아닐 경우에만 한에서 열어줄것임
    //    * 유해위험요인seq
    //    */
    //   this.rowIndex = index;
    //   this.isRowClick = true;
    //   this.$_.extend(this.sop, row);
    //   if (this.sop.editFlag !== 'C') {
    //     this.sop.editFlag = 'U'
    //     this.sop.chgUserId = this.$store.getters.user.userId
    //   }
    //   this.sopDialog = true;
    // },
    openPop(row, index) {
      /**
       * 이후에 해당 정보를 클릭 한 후 수정하려고 할 수 있도록 열것임
       * 다만 해당 마다 존재하는 키값이 temp로 따진 키가 아닐 경우에만 한에서 열어줄것임
       * 유해위험요인seq
       */
      this.rowIndex = index;
      this.isRowClick = true;
      this.$_.extend(this.sop, row);
      if (this.sop.editFlag !== 'C') {
        this.sop.editFlag = 'U'
        this.sop.chgUserId = this.$store.getters.user.userId
      }
      this.sopDialog = true;
    },
    addJob() {
      this.rowIndex = 0;
      this.isRowAdd = true;
      let groupMdmSopId = this.process.processCd + '/' + uid();
      let sop = { 
        ram4mAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
        processCd: this.process.processCd,  // 단위공정 코드
        processName: this.process.processName,
        ramTechniqueCd: 'RT00000025',
        groupMdmSopId: groupMdmSopId,
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: '',  // 안전작업 명
        ramRiskHazardId: '',  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        ram4mRiskHazardClassCd: null,  // 4M 분류 코드
        ram4mRiskHazardClassName: '',  // 4M 분류
        damageTargetName: '',  // 피해대상(key-in)
        damageTypeName: '',  // 재해형태(key-in)
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      this.process.fmScenarios.push(sop)
      // this.openPop(sop, this.rowIndex)
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      this.isRowAdd = true;
      if (col.name === 'processName') {
        this.addSop(props)
      } else if (col.name === 'jobName') {
        this.addRiskHazard(props)
      }
    },
    addSop(props) {
      let sop = { 
        ram4mAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
        processCd: this.process.processCd,  // 단위공정 코드
        processName: this.process.processName,
        ramTechniqueCd: props.row.ramTechniqueCd,
        groupMdmSopId: props.row.processCd + '/' + uid(),
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: '',  // 안전작업 명
        ramRiskHazardId: '',  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        ram4mRiskHazardClassCd: null,  // 4M 분류 코드
        ram4mRiskHazardClassName: '',  // 4M 분류
        damageTargetName: '',  // 피해대상(key-in)
        damageTypeName: '',  // 재해형태(key-in)
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      this.process.fmScenarios.push(sop)
      // this.openPop(sop, this.rowIndex)
    },
    addRiskHazard(props) {
      let sop = { 
        ram4mAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
        processCd: this.process.processCd,  // 단위공정 코드
        processName: this.process.processName,
        ramTechniqueCd: props.row.ramTechniqueCd,
        groupMdmSopId: props.row.groupMdmSopId,
        mdmSopId: props.row.mdmSopId,  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: props.row.jobName,  // 안전작업 명
        ramRiskHazardId: '',  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        ram4mRiskHazardClassCd: null,  // 4M 분류 코드
        ram4mRiskHazardClassName: '',  // 4M 분류
        damageTargetName: '',  // 피해대상(key-in)
        damageTypeName: '',  // 재해형태(key-in)
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      // this.process.fmScenarios.push(sop)
      this.openPop(sop, this.rowIndex)
    },
    searchRiskHazard() {
      this.popupOptions.title = '유해위험요인 검색';
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/base/riskHazardPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskHazardPopup;
    },
    closeRiskHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.$_.findIndex(this.$_.filter(this.process.fmScenarios, { 
          groupMdmSopId: this.sop.groupMdmSopId,
        }), { ramRiskHazardId: data[0].ramRiskHazardId }) === -1) {
          this.sop.ramRiskHazardId = data[0].ramRiskHazardId
          this.sop.riskHazardName = data[0].riskHazardName
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '해당 작업에 존재하는 유해위험요인입니다.', // 해당 작업에 존재하는 유해위험요인입니다.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    removeRiskHazard() {
      this.sop.ramRiskHazardId = null
      this.sop.riskHazardName = null
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    searchRowRiskHazard(props, col) {
      this.$set(this.rowRiskHazardInfo, 'row', props.row);
      this.$set(this.rowRiskHazardInfo, 'col', col);
      this.popupOptions.title = '유해위험요인 검색'; 
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/base/riskHazardPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowRiskHazardPopup;
    },
    closeRowRiskHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.$_.findIndex(this.$_.filter(this.process.fmScenarios, { 
          groupMdmSopId: this.rowRiskHazardInfo.row.groupMdmSopId,
        }), { ramRiskHazardId: data[0].ramRiskHazardId }) === -1) {
          this.rowRiskHazardInfo.row.ramRiskHazardId = data[0].ramRiskHazardId
          this.rowRiskHazardInfo.row.riskHazardName = data[0].riskHazardName
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '해당 작업에 존재하는 유해위험요인입니다.', // 해당 작업에 존재하는 유해위험요인입니다.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    removeRowRiskHazard(props) {
      this.$set(props.row, 'ramRiskHazardId', null)
      this.$set(props.row, 'riskHazardName', null)
    },
    jobChange() {
      if (this.isRowAdd) {
        if (this.$_.findIndex(this.process.fmScenarios, { jobName: this.sop.jobName, }) > -1) {
          this.isSameJobName = true;
        } else {
          this.isSameJobName = false;
        }
      } else {
        if (this.$_.findIndex(this.$_.reject(this.process.fmScenarios, {
          groupMdmSopId: this.sop.groupMdmSopId
        }), { jobName: this.sop.jobName, }) > -1) {
          this.isSameJobName = true;
        } else {
          this.isSameJobName = false;
        }
      }
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.isSameJobName) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '동일한 작업명이 존재합니다.', // 동일한 작업명이 존재합니다.
              type: 'warning', // success / info / warning / error
            });
            this.isSameJobName = false;
            return;
          }

          if (!this.sop.ramRiskHazardId) {
            // 유해위험요인 일련번호가 없는 경우(key-in)
            this.sop.ramRiskHazardId = uid();
          }
          if (this.isRowAdd) {
            this.process.fmScenarios.splice(this.rowIndex, 0, this.$_.clone(this.sop));
          } else {
            if (this.process.fmScenarios[this.rowIndex].jobName !== this.sop.jobName) {
              this.$_.forEach(this.process.fmScenarios, item => {
                if (item.groupMdmSopId === this.sop.groupMdmSopId) {
                  item.jobName = this.sop.jobName
                  
                  if (item.editFlag !== 'C') {
                    item.editFlag = 'U'
                    item.chgUserId = this.$store.getters.user.userId
                  }
                }
              })
            }
            this.$_.extend(this.process.fmScenarios[this.rowIndex], this.$_.clone(this.sop))
          }
          this.isRowClick = false;
          this.isRowAdd = false;
          Object.assign(this.$data.sop, this.$options.data().sop);
          this.sopDialog = false;

        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    closeDialog() {
      this.isSameJobName = false;
      this.isRowClick = false;
      this.isRowAdd = false;
      this.sopDialog = false;
    },
    saveSop() {
      if (this.$comm.validTable(this.grid.columns, this.process.fmScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.process.fmScenarios, sop => {
              sop.chgUserId = this.$store.getters.user.userId
            });

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSopCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getSops();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeSop() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = '삭제하시겠습니까?';
        if (this.updateBtnData.flag) {
          message = '현재 평가실행 중인 유해위험요인입니다.\n관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n진행하시겠습니까?'
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: message,
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.$set(this.process, 'fmScenarios', this.$_.reject(this.process.fmScenarios, item))
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePicturePopup;
    },
    closePicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
  div.upload4m
    margin: 0 !important
    padding: 0 !important
    border-radius: 0 !important
    div.col-12
      padding: 0 !important
      margin: 0 !important
      .q-uploader--bordered
        border: 0 !important
      .custom-upload-picture
        border-radius: 0 !important
        overflow: hidden !important
        .q-uploader__list
          overflow: hidden !important
          .empty_dscription
            div
              padding: 0 !important
              position: relative
              top: 2px !important
          .update-picture-card
            height: 40px !important
            .upload-picture
              height: 40px !important
  .q-btn__wrapper:before 
    box-shadow: none !important
</style>